/* eslint-disable react/prop-types */
import { jobsHostBase } from "@constants";
import { AuthContext } from "@context";
import useOpenWidgetCreateAccount from "@hooks/magnetoUi/useOpenWidgetToApply";
import { TFunction, withTranslation } from "next-i18next";
import Link from "next/link";
import { useContext } from "react";
import style from "./companies.module.scss";
import CompaniesImg from "./logo/component";
import LogoActions from "./logoActions";

type Data = {
  url: string;
  img: string;
  name: string;
};

type CompaniesLogosProps = {
  data: Data[];
  t: TFunction;
  clickCreateAccount: boolean;
};

const CompaniesLogos: React.FC<CompaniesLogosProps> = ({ data, clickCreateAccount, t }) => {
  const { openCreateAccount } = useOpenWidgetCreateAccount();
  const altText = t<string>("genericTexts:altTexts.signUp&FindJob");
  const jobRoute = t<string>("routes:jobs");

  const { isAuthenticated } = useContext(AuthContext);

  const filterCompany = (companyName: string) => {
    return jobsHostBase + jobRoute + "?q=" + companyName.replaceAll(" ", "+");
  };

  return (
    <ul className={style.contentCompanies}>
      {data.map((data, index) => (
        <li key={index}>
          {!isAuthenticated ? (
            <LogoActions
              clickCreateAccount={clickCreateAccount}
              openCreateAccount={openCreateAccount}
              data={data}
              altText={altText}
            />
          ) : (
            <Link href={filterCompany(data.name)}>
              <CompaniesImg data={data} alt={altText} />
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default withTranslation()(CompaniesLogos);
