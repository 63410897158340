/* eslint-disable react/prop-types */
import { TWpGlobal } from "@@types/wordPress";
import parser from "html-react-parser";
import Image from "next/image";
import Link from "next/link";
import ButtonCustom from "@components/buttons/btnCustom/component";
import style from "./blockReferral.module.scss";
import { logoB2bPymes } from "@constants";

type DataProps = {
  data: TWpGlobal;
};

type ImageTextProps = {
  data: TWpGlobal;
  pymes?: boolean;
};

const Referral: React.FC<DataProps> = ({ data }) => {
  const dataReferrals = data.acf.chat_bot_referrals;
  return (
    <>
      {dataReferrals.display && (
        <div className={style.referralBox} style={{ backgroundColor: `${dataReferrals.background}` }}>
          <Link href={dataReferrals.link} target="_blank">
            <Image src={dataReferrals.image} width={90} height={90} alt="referral" priority />
            <div className={style.infoReferral}>
              <div>
                <p>{parser(dataReferrals.title)}</p>
                <small> {parser(dataReferrals.text)}</small>
              </div>
              <i className="las la-angle-right"></i>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

const Pymes: React.FC<DataProps> = ({ data }) => {
  const dataPymes = data.acf.pymes;
  return (
    <>
      {dataPymes.display && (
        <div className={style.pymesBox} style={{ backgroundColor: `${dataPymes.background}` }}>
          <div className={style.infoPymes}>
            <Image src={logoB2bPymes} alt="pymes" width={150} height={60} />
            <div>
              <p>{parser(dataPymes.title)}</p>
              <small> {parser(dataPymes.text)}</small>
            </div>
            <ButtonCustom
              href={dataPymes.button.link}
              txt={dataPymes.button.text}
              bgc={dataPymes.button.color_button}
              txtColor={dataPymes.button.color_text}
              icon
              iconClass="las la-angle-right"
            />
          </div>

          <Image src={dataPymes.image} width={461} height={279} alt="magneto pymes" />
        </div>
      )}
    </>
  );
};

const ImageText: React.FC<ImageTextProps> = ({ data, pymes }) => {
  return <>{pymes ? <Pymes data={data} /> : <Referral data={data} />}</>;
};

export default ImageText;
