/* eslint-disable react/prop-types */
import { jobsHostBase } from "@constants";
import Link from "next/link";
import CompaniesImg from "./logo/component";
import style from "./companies.module.scss";

type Data = {
  url: string;
  img: string;
  name: string;
};

type LogoActionsProps = {
  clickCreateAccount: boolean;
  openCreateAccount: () => void;
  data: Data;
  altText: string;
};

const LogoActions: React.FC<LogoActionsProps> = ({ clickCreateAccount, openCreateAccount, data, altText }) => {
  return (
    <>
      {clickCreateAccount ? (
        <button onClick={openCreateAccount} className={style.logoCreateAccount}>
          <CompaniesImg data={data} alt={altText} />
        </button>
      ) : data.url ? (
        <Link href={`${jobsHostBase}${data.url}`}>
          <CompaniesImg data={data} alt={altText} />
        </Link>
      ) : (
        <CompaniesImg data={data} alt={altText} />
      )}
    </>
  );
};

export default LogoActions;
