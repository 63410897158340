import { ISeoData, TWpGlobal } from "#types/wordPress";
import MetaInfo from "@components/common/seoTags/seo-validation";
import ContentHomeB2C from "@components/contentPages/contentHomeV2/component";
import MagnetoUIHeader from "@components/layouts/layoutB2C/headers/magnetoUiHeader";
import ButtonUserBack from "@components/userback/component";
import { HOME_PAGE_V2_ID, localsFiles, SEO_HOME_V2, SLIDER_HOME, valueRevalidate } from "@constants";
import { ELandingType } from "@enums/searchHit.enum ";
import withMegaMenu from "@hoc/withMengaMenu.hoc";
import useAutomaticRegisterModal from "@hooks/useAutomaticRegisterModal";
import { CptRepository, MegaMenuRepository, PagesRepository, PostRepository } from "@repositories";
import { backendErrorHandler } from "@utils/common/backendErrorHandler";
import { GetStaticProps } from "next";
import { withTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React, { Fragment } from "react";

type HomeB2Cv2Props = {
  data: TWpGlobal;
  dataSeo: ISeoData;
  dataSlider: TWpGlobal[];
  dataBlog: TWpGlobal[];
};

const HomeB2Cv2: React.FC<HomeB2Cv2Props> = ({ data, dataSeo, dataSlider, dataBlog }) => {
  useAutomaticRegisterModal();

  return (
    <Fragment>
      <MetaInfo metaData={dataSeo.head} />
      <MagnetoUIHeader />
      <ContentHomeB2C data={data} dataSlider={dataSlider} dataBlog={dataBlog} />
      <ButtonUserBack />
    </Fragment>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  if (!context.locale) throw new Error("locale is undefined");
  try {
    const [data, dataSeo, dataSlider, dataBlog, landings, i18nProps] = await Promise.all([
      PagesRepository.getPageById(HOME_PAGE_V2_ID),
      PagesRepository.getSeoPage(SEO_HOME_V2),
      CptRepository.getSliderByCategory(SLIDER_HOME),
      PostRepository.getAllPosts(),
      MegaMenuRepository.getJobsByType(ELandingType.BY_LOCATION),
      serverSideTranslations(context.locale, localsFiles)
    ]);
    return {
      props: {
        data,
        dataSeo,
        dataSlider,
        dataBlog,
        landings,
        ...i18nProps
      },
      revalidate: valueRevalidate
    };
  } catch (error) {
    return backendErrorHandler(error);
  }
};

const MegaMenuPage = withMegaMenu(HomeB2Cv2);
export default withTranslation()(MegaMenuPage);
